import { DigitalRecordsFacility, DigitalRecordsRequest } from '../models';

export class LoadRecordsRequest {
  static readonly type = '[DigitalRecordsRequest] Load Records Request';
}

export class LoadRecordsRequestSuccess {
  static readonly type = '[DigitalRecordsRequest] Load Records Request Success';
  constructor(public payload: DigitalRecordsRequest) {}
}

export class LoadRecordsRequestFail {
  static readonly type = '[DigitalRecordsRequest] Load Records Request Fail';
  constructor(public err: any) {}
}

export class InitRecordsRequest {
  static readonly type = '[DigitalRecordsRequest] Init Records Request';
}

export class InitRecordsRequestSuccess {
  static readonly type = '[DigitalRecordsRequest] Init Records Request Success';
  constructor(public payload: DigitalRecordsRequest[]) {}
}

export class CreateRecordsRequest {
  static readonly type = '[DigitalRecordsRequest] Create Records Request';
  constructor(public recordsRequest: DigitalRecordsRequest) {}
}

export class CreateRecordsRequestSuccess {
  static readonly type = '[DigitalRecordsRequest] Create Records Request Success';
  constructor(public payload: DigitalRecordsRequest) {}
}

export class SetStep {
  static readonly type = '[DigitalRecordsRequest] Set step';
  constructor(public step: number) {}
}

export class SaveStep {
  static readonly type = '[DigitalRecordsRequest] Save step';
  constructor(public step: number) {}
}

export class UpdateRecordsRequest {
  static readonly type = '[DigitalRecordsRequest] Update Records Request';
  constructor(public updateInfo: any, public step: number) {}
}

export class UpdateRecordsRequestSuccess {
  static readonly type = '[DigitalRecordsRequest] Update Records Request Success';
  constructor(public payload: DigitalRecordsRequest, public step: number) {}
}

export class UpdateRecordsRequestFail {
  static readonly type = '[DigitalRecordsRequest] Update Records Request Fail';
  constructor(public err: any) {}
}

export class SendRecordsRequest {
  static readonly type = '[DigitalRecordsRequest] Send Records Request';
  constructor(public step: number) {}
}

export class SendRecordsRequestSuccess {
  static readonly type = '[DigitalRecordsRequest] Send Records Request Success';
  constructor(public payload: DigitalRecordsRequest, public step: number) {}
}

export class LoadFacilities {
  static readonly type = '[LoadFacilities] Load Facilities';
  constructor(public recordsRequestId: number) {}
}

export class LoadFacilitiesSuccess {
  static readonly type = '[DigitalRecordsRequest] Load Facilities Success';
  constructor(public payload: DigitalRecordsFacility[]) {}
}

export class CreateFacilitySuccess {
  static readonly type = '[DigitalRecordsRequest] Create Facility Success';
  constructor(public payload: DigitalRecordsFacility) {}
}

export class SaveFacilitySuccess {
  static readonly type = '[DigitalRecordsRequest] Save Facility Success';
  constructor(public payload: DigitalRecordsFacility) {}
}

export class DeleteFacilitySuccess {
  static readonly type = '[DigitalRecordsRequest] Delete Facility Success';
  constructor(public facilityId: number) {}
}
