import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { BackendError } from '../../../messages/messages.actions';
import { DigitalRecordsRequestStateModel, RECORDS_REQUEST_ACTIVE_STATUSES, RECORDS_REQUEST_STATUS } from '../models';
import { DigitalMriRecordsService } from '../services/digital-mri-records.service';
import {
  CreateFacilitySuccess,
  CreateRecordsRequest,
  CreateRecordsRequestSuccess,
  DeleteFacilitySuccess,
  InitRecordsRequest,
  InitRecordsRequestSuccess,
  LoadFacilities,
  LoadFacilitiesSuccess,
  LoadRecordsRequest,
  LoadRecordsRequestFail,
  LoadRecordsRequestSuccess,
  SaveFacilitySuccess,
  SaveStep,
  SendRecordsRequest,
  SendRecordsRequestSuccess,
  SetStep,
  UpdateRecordsRequest,
  UpdateRecordsRequestFail,
  UpdateRecordsRequestSuccess
} from './mri-records.actions';

const STATE_DEFAULTS: DigitalRecordsRequestStateModel = {
  recordsRequest: null,
  step: null,
  maxStep: 1,

  facilities: [],

  requestInitialized: false,
};

@State<DigitalRecordsRequestStateModel>({
  name: 'digital_records_request',
  defaults: STATE_DEFAULTS,
})

@Injectable()
export class DigitalRecordsRequestState implements NgxsOnInit {
  constructor(
    private digitalRecordsService: DigitalMriRecordsService,
  ) {}

  @Selector()
  static recordsRequest(state: DigitalRecordsRequestStateModel) {
    return state.recordsRequest;
  }

  @Selector()
  static step(state: DigitalRecordsRequestStateModel) {
    return state.step;
  }

  @Selector()
  static maxStep(state: DigitalRecordsRequestStateModel) {
    return state.maxStep;
  }

  @Selector()
  static facilities(state: DigitalRecordsRequestStateModel) {
    return state.facilities;
  }

  @Selector()
  static isRequestSubmitted(state: DigitalRecordsRequestStateModel) {
    if (state.recordsRequest) {
      return state.recordsRequest.status && state.recordsRequest.status !== RECORDS_REQUEST_STATUS.CREATED ? true : false;
    }

    return null;
  }

  @Selector()
  static requestInitialized(state: DigitalRecordsRequestStateModel) {
    return state.requestInitialized;
  }

  @Selector()
  static isSettingsProgressVisible(state: DigitalRecordsRequestStateModel) {
    if (state.recordsRequest) {
      return [...RECORDS_REQUEST_ACTIVE_STATUSES, RECORDS_REQUEST_STATUS.COMPELETED].includes(state.recordsRequest.status);
    }

    return null;
  }

  @Selector()
  static isRequestActive(state: DigitalRecordsRequestStateModel) {
    if (state.recordsRequest) {
      return RECORDS_REQUEST_ACTIVE_STATUSES.includes(state.recordsRequest.status);
    }

    return null;
  }

  ngxsOnInit({dispatch}: StateContext<DigitalRecordsRequestState>): void | any {
  }

  @Action(LoadRecordsRequest)
  loadRecordsRequest({dispatch, patchState}: StateContext<DigitalRecordsRequestStateModel>) {
    patchState({
      recordsRequest: null,
      step: null,
      maxStep: 1
    });

    this.digitalRecordsService.getRecordsRequests().subscribe(
      data => {
        const lastRequest = data.length ? data[data.length - 1] : null;

        if (lastRequest && lastRequest.status !== RECORDS_REQUEST_STATUS.COMPELETED) {
          dispatch([new LoadRecordsRequestSuccess(lastRequest)]);
        } else {
          dispatch(new CreateRecordsRequest({}));
        }
      },
      err => dispatch(new LoadRecordsRequestFail(err))
    );
  }

  @Action(LoadRecordsRequestSuccess)
  loadRecordsRequestSuccess({patchState, dispatch}: StateContext<DigitalRecordsRequestStateModel>, {payload}: LoadRecordsRequestSuccess) {
    let step = this.digitalRecordsService.getCurrentStep() || 1;
    let maxStep = this.digitalRecordsService.getMaxStep() || 1;

    if (payload.status && payload.status !== RECORDS_REQUEST_STATUS.CREATED) {
      step = 4;
      maxStep = 4;
    }

    patchState({
      recordsRequest: payload,
      step,
      maxStep,
    });

    dispatch(new LoadFacilities(payload.id));
  }

  @Action(LoadRecordsRequestFail)
  loadRecordsRequestFail({dispatch}: StateContext<DigitalRecordsRequestStateModel>, {err}: LoadRecordsRequestFail) {
    dispatch(new BackendError(err));
  }

  // Used for header notification about request progress
  @Action(InitRecordsRequest)
  initRecordsRequest({dispatch, patchState}: StateContext<DigitalRecordsRequestStateModel>) {
    this.digitalRecordsService.getRecordsRequests().subscribe(
      data => dispatch(new InitRecordsRequestSuccess(data)),
      err => dispatch(new BackendError(err))
    );
  }

  @Action(InitRecordsRequestSuccess)
  initRecordsRequestSuccess({patchState, dispatch}: StateContext<DigitalRecordsRequestStateModel>, {payload}: InitRecordsRequestSuccess) {
    const lastRequest = payload.length ? payload[payload.length - 1] : null;

    if (lastRequest) {
      patchState({
        recordsRequest: lastRequest,
      });
    }

    patchState({
      requestInitialized: true
    });
  }

  @Action(CreateRecordsRequest)
  createRecordsRequest({dispatch}: StateContext<DigitalRecordsRequestStateModel>, {recordsRequest}: CreateRecordsRequest) {
    this.digitalRecordsService.createRecordsRequest(recordsRequest).subscribe(
      data => dispatch(new CreateRecordsRequestSuccess(data)),
      err => dispatch(new BackendError(err))
    );
  }

  @Action(CreateRecordsRequestSuccess)
  createRecordsRequestSuccess({patchState, dispatch}: StateContext<DigitalRecordsRequestStateModel>, {payload}: LoadRecordsRequestSuccess) {
    this.digitalRecordsService.saveMaxStep(1);
    this.digitalRecordsService.saveCurrentStep(1);

    patchState({
      recordsRequest: payload,
      step: 1,
      maxStep: 1
    });

    dispatch(new LoadFacilities(payload.id));
  }

  @Action(UpdateRecordsRequest)
  updateRecordsRequest(
    {getState, dispatch}: StateContext<DigitalRecordsRequestStateModel>,
    {updateInfo, step}: UpdateRecordsRequest
  ) {

    const requestId = getState().recordsRequest.id;

    this.digitalRecordsService.updateRecordsRequest(requestId, updateInfo).subscribe(
      data => dispatch(new UpdateRecordsRequestSuccess(data, step)),
      err => dispatch(new UpdateRecordsRequestFail(err))
    );
  }

  @Action(UpdateRecordsRequestSuccess)
  updateRecordsRequestSuccess(
    {getState, patchState}: StateContext<DigitalRecordsRequestStateModel>,
    {payload, step}: UpdateRecordsRequestSuccess
  ) {

    const recordsRequest = getState().recordsRequest;

    let currentMaxStep = getState().maxStep;

    if (step > currentMaxStep) {
      currentMaxStep = step;
      this.digitalRecordsService.saveMaxStep(currentMaxStep);
    }

    this.digitalRecordsService.saveCurrentStep(step);

    patchState({
      recordsRequest: {...recordsRequest, ...payload},
      step,
      maxStep: currentMaxStep
    });
  }

  @Action(UpdateRecordsRequestFail)
  updateRecordsRequestFail({dispatch}: StateContext<DigitalRecordsRequestStateModel>, {err}: UpdateRecordsRequestFail) {
    dispatch(new BackendError(err));
  }

  // Used when user was already charged
  @Action(SendRecordsRequest)
  sendRecordsRequest({getState, dispatch}: StateContext<DigitalRecordsRequestStateModel>, {step}: SendRecordsRequest) {
    const recordsRequestInfo = getState().recordsRequest;

    this.digitalRecordsService.sendRecordsRequest(recordsRequestInfo.id, recordsRequestInfo).subscribe(
      data => dispatch(new SendRecordsRequestSuccess(data, step)),
      err => dispatch(new BackendError(err))
    );
  }

  @Action(SendRecordsRequestSuccess)
  sendRecordsRequestSuccess(
    {getState, patchState}: StateContext<DigitalRecordsRequestStateModel>,
    {payload, step}: SendRecordsRequestSuccess
  ) {
    const recordsRequest = getState().recordsRequest;

    let currentMaxStep = getState().maxStep;

    if (step > currentMaxStep) {
      currentMaxStep = step;
      this.digitalRecordsService.saveMaxStep(currentMaxStep);
    }

    this.digitalRecordsService.saveCurrentStep(step);

    patchState({
      recordsRequest: {...recordsRequest, ...payload},
      step,
      maxStep: currentMaxStep
    });
  }

  @Action(SetStep)
  setStep({patchState}: StateContext<DigitalRecordsRequestStateModel>, {step}: SetStep) {
    patchState({step});
  }

  @Action(SaveStep)
  saveStep({getState, patchState}: StateContext<DigitalRecordsRequestStateModel>, {step}: SaveStep) {
    let currentMaxStep = getState().maxStep;

    if (step > currentMaxStep) {
      currentMaxStep = step;
      this.digitalRecordsService.saveMaxStep(currentMaxStep);
    }

    this.digitalRecordsService.saveCurrentStep(step);

    patchState({
      step,
      maxStep: currentMaxStep
    });
  }

  @Action(LoadFacilities)
  loadFacilities({dispatch, getState}: StateContext<DigitalRecordsRequestStateModel>, {recordsRequestId}: LoadFacilities) {
    this.digitalRecordsService.getFacilities(recordsRequestId).subscribe(
      data => dispatch(new LoadFacilitiesSuccess(data)),
      err => dispatch(new BackendError(err))
    );
  }

  @Action(LoadFacilitiesSuccess)
  loadFacilitiesSuccess({patchState}: StateContext<DigitalRecordsRequestStateModel>, {payload}: LoadFacilitiesSuccess) {
    patchState({
      facilities: payload,
    });
  }

  @Action(CreateFacilitySuccess)
  createFacilitySuccess({patchState, getState}: StateContext<DigitalRecordsRequestStateModel>, {payload}: CreateFacilitySuccess) {
    const facilities = getState().facilities;

    patchState({
      facilities: [...facilities, payload]
    });
  }

  @Action(SaveFacilitySuccess)
  saveFacilitySuccess({patchState, getState}: StateContext<DigitalRecordsRequestStateModel>, {payload}: SaveFacilitySuccess) {
    const facilities = [...getState().facilities];
    const facilityIndex = facilities.findIndex(facility => facility.id === payload.id);

    if (facilityIndex !== -1) {
      facilities[facilityIndex] = payload;
    }

    patchState({
      facilities
    });
  }

  @Action(DeleteFacilitySuccess)
  deleteFacilitySuccess({patchState, getState}: StateContext<DigitalRecordsRequestStateModel>, {facilityId}: DeleteFacilitySuccess) {
    const facilities = getState().facilities;
    const filteredFacilities = facilities.filter(item => item.id !== facilityId)

    patchState({
      facilities: filteredFacilities
    });
  }

}
