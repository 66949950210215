export class DigitalRecordsRequestStateModel {
  recordsRequest: DigitalRecordsRequest;
  facilities: DigitalRecordsFacility[];

  step: number;
  maxStep: number;

  requestInitialized: boolean;
}

export interface DigitalRecordsRequest {
  id?: number;
  status?: number;
  verbose_status?: string;

  first_name?: string;
  last_name?: string;
  alternate_names?: string[];
  date_of_birth?: string;
  phone_number?: string;
  ssn?: string;

  agree?: string;
  signature?: string;

  price?: number;
  token?: string;
  coupon_code?: string;
}

export interface DigitalRecordsFacility {
  id?: number;
  name?: string;
  address?: string;
  details?: string;
}

export enum RECORDS_REQUEST_STATUS {
  ERROR = 3,
  CREATED = 1,
  PAID = 2,
  CONTACTED = 5,
  RECEIVED = 6,
  UPLOADED = 7,
  COMPELETED = 4, // last status
}

export const RECORDS_REQUEST_ACTIVE_STATUSES = [2, 5, 6, 7];
